import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  AboutSection,
  LoadingBar,
  LoadingState,
  ReportSection,
  StickyNav,
} from "../../components";
import { API_CONSTANTS, ERROR_CONSTANTS } from "../../types/constants/";
import SomethingWentWrongPage from "../somethingWentWrongPage/SomethingWentWrongPage";
import { getStateAgencyById } from "./stateAgencyPage.service";
import {
  DollarBillIcon,
  IdahoIcon,
  PayIcon,
  ThreeDotCircleIcon,
} from "../../styles/icons";
import StateAgenciesSearch from "./stateAgencyComponents/StateAgenciesSearch";
import StateAgencyAdditionalReports from "./stateAgencyComponents/StateAgencyAdditionalReports";
import "./stateAgencyPage.scss";
import "../landingPages.scss";

type Agency = {
  EntityID: number;
  Narratives: string;
  HeroBlurb: string;
  EntityName: string;
  Classification: string;
  Agency_Website: string;
  AgencyNumber: number;
  AgencyActualStoriesURL: string;
  AgencyActualStoriesiFrame: string;
  AgencyAgreements: string;
  EmployeeCountURL: string;
  EmployeeSalaryURL: string;
  PayRateURL: string;
  PayRateiFrame: string;
  PayRatesControlPanelURL: string;
  ProjectedRetirementURL: string;
  SpecificBudgetStoriesURL: string;
  SpecificBudgetStoriesiFrame: string;
  TenureURL: string;
  TopVendorMostUsedURL: string;
  TopVendorMostUsediFrame: string;
  TopVendorTotalSpentURL: string;
  TopVendorTotalSpentiFrame: string;
  TransactionReportURL: string;
  TravelReportURL: string;
};

const StateAgencyPage = () => {
  const { id } = useParams<{ id: string }>();
  const [showErrorPage, setShowErrorPage] = useState<boolean>(false);
  const [stateAgency, setStateAgency] = useState<Agency>();

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Idle
  );
  const aboutRef = useRef<HTMLDivElement>(null);
  const stateAgencyAdditionalReportsRef = useRef<HTMLDivElement>(null);
  const stateAgenciesRef = useRef<HTMLDivElement>(null);
  const employeePayRateRef = useRef<HTMLDivElement>(null);
  const budgetToActualRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const handleGetStateAgencyById = async (id: string) => {
      setLoadingState(LoadingState.Starting);
      const data = await getStateAgencyById(id);
      if (data === ERROR_CONSTANTS.SERVER_ERROR) {
        setShowErrorPage(true);
      } else if (!data.Classification) {
        navigate("/*");
      } else {
        setStateAgency(data);
      }
      setLoadingState(LoadingState.Finished);
    };
    if (id) {
      handleGetStateAgencyById(id);
    }
  }, [id]);

  function handleClassification(): number | undefined {
    if (!stateAgency?.Classification) return undefined;

    switch (stateAgency?.Classification) {
      case "Economic Development":
        return 1;
      case "Education":
        return 2;
      case "Elected Officials":
        return 3;
      case "General Government":
        return 4;
      case "Health and Human Services":
        return 5;
      case "Judicial Branch":
        return 6;
      case "Legislative Branch":
        return 7;
      case "Military":
        return 8;
      case "Natural Resources":
        return 9;
      case "Public Safety":
        return 10;
      case "Self Governing Agencies":
        return 11;
    }
  }

  function handleAboutImage(): string {
    return handleClassification() !== undefined
      ? API_CONSTANTS.imageBucket +
          `/TransparentIdahoLogos/Agency+About+Photos/AboutImage${handleClassification()}.png`
      : API_CONSTANTS.imageBucket +
          `/TransparentIdahoLogos/Agency+Header+Photos/StateAgency${stateAgency?.AgencyNumber}.jpeg`;
  }

  return showErrorPage ? (
    <SomethingWentWrongPage />
  ) : (
    <div className="county-page-container">
      <LoadingBar
        loadingState={loadingState}
        setLoadingState={setLoadingState}
      />
      <header>
        <Header />
      </header>
      <main>
        {stateAgency?.AgencyNumber && (
          <>
            <div
              className="landing-page-hero-container state-agency-background-image"
              style={{
                alignItems: "center",
                backgroundPosition: "center right",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  "url(" +
                  API_CONSTANTS.imageBucket +
                  `/TransparentIdahoLogos/Agency+Header+Photos/StateAgency${stateAgency?.AgencyNumber}.jpeg` +
                  ")",
              }}
            >
              <span className="explore-section state glass" tabIndex={0}>
                <div className="">
                  <span className="">
                    <h2 className="county-header">{stateAgency.EntityName}</h2>
                    <h6 className="county-text">{stateAgency?.HeroBlurb}</h6>
                  </span>
                </div>
                {/* <div className="landing-page-info-bubbles">
                  <InfoBubble
                    titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderTotalBudgetLight.svg`}
                    infoDetailText={"Districts"}
                    Icon={CountyIconBubbles}
                    infoAmount={"832"}
                    bottomHeader={`
                
                    `}
                  />
                  <InfoBubble
                    titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderAgencyEmployeesLight.svg`}
                    infoDetailText={"Types"}
                    Icon={CountyIconBubbles}
                    infoAmount={"38"}
                    bottomHeader={`
                    
                    `}
                  />
                  <InfoBubble
                    titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderWorkforceTenureLight.svg`}
                    Icon={CountyIconBubbles}
                    infoAmount={"49"}
                    infoDetailText={"Agencies"}
                    bottomHeader={`
                    
                    `}
                  />
                </div> */}
              </span>
            </div>
            <StickyNav
              sections={[
                {
                  icon: <PayIcon />,
                  text: "About",
                  ref: aboutRef,
                },
                {
                  icon: <DollarBillIcon />,
                  text: "Actuals",
                  ref: budgetToActualRef,
                },
                {
                  icon: <ThreeDotCircleIcon />,
                  text: "Employee Pay Rate",
                  ref: employeePayRateRef,
                },
                {
                  icon: <IdahoIcon />,
                  text: "State Agencies",
                  ref: stateAgenciesRef,
                },
              ]}
            />
            <AboutSection
              imageUrl={handleAboutImage()}
              imageAltText={"State Agency Logo"}
              title={stateAgency.EntityName}
              description={stateAgency.Narratives}
              dataInfo={""}
              ref={aboutRef}
            />
            <div className="app-padding">
              <h4 className="county-page-report-header">
                Explore Agency Reports
              </h4>
              <p className="body-large county-page-report-subheader">
                Explore the {stateAgency.EntityName} specific financial and
                workforce reports below.
              </p>
            </div>
            {stateAgency.SpecificBudgetStoriesiFrame && (
              <ReportSection
                title={"Budget"}
                description={
                  "The Budget Report provides details about each agency's anticipated revenues and proposed spending for a fiscal year. "
                }
                reportId={""}
                openGovReport={stateAgency.SpecificBudgetStoriesiFrame ?? ""}
                newTabLink={stateAgency.SpecificBudgetStoriesURL ?? ""}
              />
            )}
            {stateAgency.AgencyActualStoriesiFrame && (
              <ReportSection
                title={"Actuals"}
                description={
                  "The Monthly Actuals report allows you to track state agency expenditures against their defined budget. This process mirrors the discipline of setting a personal yearly budget and monitoring spending after each pay period. Actuals will appear incrementally month to month."
                }
                reportId={""}
                ref={budgetToActualRef}
                openGovReport={stateAgency.AgencyActualStoriesiFrame ?? ""}
                newTabLink={stateAgency.AgencyActualStoriesURL ?? ""}
              />
            )}
            {stateAgency.PayRateiFrame && (
              <ReportSection
                title={"Employee Pay Rates"}
                description={
                  "The Employee Pay Rate Report allows you to see the salary information for employees at each state agency. This report offers a comprehensive breakdown of employee pay rates. Important: Be aware that employees holding multiple positions will have each role they fulfill listed and may appear more than once."
                }
                reportId={""}
                openGovReport={stateAgency.PayRateiFrame ?? ""}
                newTabLink={stateAgency.PayRateURL ?? ""}
                ref={employeePayRateRef}
              />
            )}
            {stateAgency.TopVendorTotalSpentiFrame && (
              <ReportSection
                title={"Top Vendor - Total Spent"}
                description={
                  "The Vendor Report enables users to pinpoint which vendors each state agency spent the most money with. Understand the intricacies of purchases, services, and financial exchanges, with a granular understanding of how public funds are utilized. "
                }
                reportId={""}
                openGovReport={stateAgency.TopVendorTotalSpentiFrame ?? ""}
                newTabLink={stateAgency.TopVendorTotalSpentURL ?? ""}
              />
            )}
            {stateAgency.TopVendorMostUsediFrame && (
              <ReportSection
                title={"Top Vendor - Most Used"}
                description={
                  "The Top Vendor Report offers a vendor transaction overview of the foremost vendors utilized by each state agency. Providing details into purchases, services, and financial exchanges and a granular understanding of how public funds are strategically employed. "
                }
                reportId={""}
                openGovReport={stateAgency.TopVendorMostUsediFrame ?? ""}
                newTabLink={stateAgency.TopVendorMostUsedURL ?? ""}
              />
            )}

            <br />
            <br />
            <StateAgencyAdditionalReports
              ref={stateAgencyAdditionalReportsRef}
              stateAgencyId={stateAgency?.EntityID ?? ""}
              workForceTenureUrl={stateAgency?.TenureURL ?? ""}
              employeeCountUrl={stateAgency?.EmployeeCountURL ?? ""}
              projectedRetirementUrl={stateAgency?.ProjectedRetirementURL ?? ""}
              agencyAgreementUrl={stateAgency?.AgencyAgreements ?? ""}
              transactionReportUrl={stateAgency?.TransactionReportURL ?? ""}
              travelReportUrl={stateAgency?.TravelReportURL ?? ""}
              agencySpendPlanUrl={""} // TODO: add url
            />
            <br />
            <br />

            <span ref={stateAgenciesRef}>
              <StateAgenciesSearch />
            </span>
          </>
        )}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default StateAgencyPage;
