import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HatGraduation } from "styled-icons/fluentui-system-filled";
import { City } from "styled-icons/boxicons-solid";
import IdahoStateSeal from "../../styles/images/idahoStateSeal.webp";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { AboutSection, InfoBubble, ReportSection } from "../../components";
import { DollarSignIcon } from "../../styles/icons";
import {
  NAV_TAGS,
  EDUCATION_EMBEDDED_REPORTS,
  API_CONSTANTS,
  ABOUT_SECTION_CONSTANTS,
} from "../../types/constants";
import "../landingPages.scss";
import "./educationLandingPage.scss";

const EducationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const exploreDataRef = useRef<HTMLDivElement>(null);
  const yearOverYearRef = useRef<HTMLDivElement>(null);
  const educationSalaryCostsRef = useRef<HTMLDivElement>(null);
  const enrollmentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (state) {
      if (state.tag === NAV_TAGS.EXPLORE_EDUCATION_DATA) {
        exploreDataRef.current?.scrollIntoView({ behavior: "smooth" });
      } else if (state.tag === NAV_TAGS.EDUCATION_YEAR_OVER_YEAR) {
        yearOverYearRef.current?.scrollIntoView({ behavior: "smooth" });
      } else if (state.tag === NAV_TAGS.EDUCATION_SALARY_COSTS) {
        educationSalaryCostsRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  return (
    <div className="landing-page-container landing-page-picture-background">
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <span className="landing-page-hero-data">
            <div className="landing-page-hero-info">
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSeal}
                alt="Idaho State Seal"
              />
              <span className="landing-page-explore-info-text">
                <h2 className="landing-page-hero-header">Explore Education</h2>
                <h6 className="landing-page-hero-subheader">
                  Idaho has a multitude of education providers. Their roles in
                  educating the next generation is vital to the success of the
                  state.
                </h6>
              </span>
            </div>
            <div className="landing-page-info-bubbles">
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderStudents.svg`}
                Icon={HatGraduation}
                infoAmount={"313,683"}
                infoDetailText="Students"
              />
              <InfoBubble
                Icon={City}
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderProviders.svg`}
                infoAmount={"201"}
                infoDetailText="Providers"
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderStateAppropriation.svg`}
                Icon={DollarSignIcon}
                infoAmount={"3.383"}
                infoDetailText="Billion"
              />
            </div>
          </span>
        </div>
        <span className="hero-separator" />
        <AboutSection
          imageUrl={API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-4.png"}
          imageAltText={ABOUT_SECTION_CONSTANTS.EDUCATION.IMG_ALT}
          title={ABOUT_SECTION_CONSTANTS.EDUCATION.TITLE}
          description={ABOUT_SECTION_CONSTANTS.EDUCATION.DESCRIPTION}
          dataInfo={ABOUT_SECTION_CONSTANTS.EDUCATION.DATA_INFO}
          ref={exploreDataRef}
        />
        <div className="landing-page-reports-container landing-page-background">
          <div className="app-padding">
            <h4 className="landing-page-high-level-header">
              EXPLORE EDUCATION DATA
            </h4>
          </div>
          <div className="financial-data-section">
            <div className="app-padding">
              <h4 className="landing-page-high-level-header">Financial Data</h4>
              <p className="body-large landing-page-high-level-subheader">
                Below you will find interactive reports for year over year
                financials and transaction detail. See information next to
                reports for helpful descriptions and insights.
              </p>
            </div>
          </div>
          <ReportSection
            ref={yearOverYearRef}
            scrollable
            title="Year Over Year Financials"
            description={
              "Annually all LEAs are to provide audited annual revenue and expenditure data to the State Board of Education and State Department of Education. To learn more about the year-over-year education financial trends, dive into the interactive data to see the revenue and spending data for the various education providers. This data includes a checkbook-level analysis of incoming and outgoing funds. \n\nWith this checkbook analysis, you can compare revenue and expenditure trends from one education provider to another as well as yearly trends within the data to better understand the financial activity within Idaho’s education system."
            }
            reportId={
              EDUCATION_EMBEDDED_REPORTS.EducationYearOverYearFinancials
                .reportId
            }
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-year-over-year-financials.png"
            altDownloadLink={
              "https://idaho-sco-central-registry-documents.s3-us-gov-west-1.amazonaws.com/PowerBi-Report-Documents/School+District+Aggregated+OpenGov+Consolidated.xlsx"
            }
          />
          <ReportSection
            ref={yearOverYearRef}
            scrollable
            title="Transaction Detail"
            description={
              "The Transaction Detail Report offers a comprehensive and transparent breakdown of financial transactions made by Idaho’s public K-12 education providers. This intuitive and user-friendly tool empowers citizens to gain insights into the allocation of taxpayer dollars across a wide range of categories. \n\nWith the capability to drill down to the individual expense level, the report provides detailed financial data, offering a closer look at how funds are spent on daily basis. It is important to note that some education providers report financial data only at the district level while other will report at the school level."
            }
            reportId={
              EDUCATION_EMBEDDED_REPORTS.EducationTransactionDetails.reportId
            }
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-education-transaction-details.png"
            altDownloadLink={
              "https://idaho-sco-central-registry-documents.s3.us-gov-west-1.amazonaws.com/PowerBi-Report-Documents/Idaho+Education+Transaction+Detail.xlsx"
            }
          />
        </div>
        <div className="landing-page-reports-container salaries-section">
          <div className="app-padding">
            <h4 className="landing-page-high-level-header">Salaries</h4>
            <p className="body-large landing-page-high-level-subheader">
              Below you can explore information on education employment
              contracts, salaries, and other position and work assignment detail
              for education providers in the state of Idaho.
            </p>
          </div>
          <ReportSection
            ref={educationSalaryCostsRef}
            scrollable
            title="Education Salary"
            description="This report provides a comprehensive overview of employment details for classified and certified education personnel. It includes the detailed pay rate and position information for classified education providers, offering insight into the roles and compensation structures that support educational operations. Additionally, the report includes certified employment details, highlighting key contract elements such as total contract amounts and contract lengths."
            reportId={EDUCATION_EMBEDDED_REPORTS.EducationSalaries.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-education-salary.png"
            blue
          />
        </div>
        <div className="landing-page-reports-container landing-page-background">
          <div className="app-padding">
            <h4 className="landing-page-high-level-header">Enrollment</h4>
            <p className="body-large landing-page-high-level-subheader">
              Below you will find interactive reports with education enrollment
              details. See information next to reports for helpful descriptions
              and insights.
            </p>
          </div>
          <ReportSection
            ref={enrollmentRef}
            scrollable
            title="Enrollment by School"
            description="This dataset reflects the number of unique K-12 students enrolled on the official Fall enrollment date, which is the first Friday in November of each year. It excludes students reported as private or home-schooled. Students who are dual enrolled on this date are counted once at each school they attend, once at each district they belong to, and only once at the state level. This data provides a snapshot of public school enrollment across Idaho."
            reportId={EDUCATION_EMBEDDED_REPORTS.EnrollmentBySchool.repordId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-enrollment-by-school.png"
          />
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default EducationPage;
