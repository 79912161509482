import { models } from "powerbi-client";
import DownloadDataIcon from "../../styles/icons/DownloadDataIcon";
import { forwardRef, useState } from "react";
import { API_CONSTANTS } from "../../types/constants";
import DownloadDataInformational from "./DownloadData";
import LazyLoadIframe from "../lazyLoads/lazyLoadIframe/LazyLoadIframe";
import LazyLoadPowerBi from "../lazyLoads/lazyLoadPowerbi/LazyLoadPowerBi";
import Loader from "../../styles/loader.svg";
import "./reportSection.scss";
import { SlicerFilter } from "../powerBi/PowerBi";

type ReportSectionProps = {
  title: string;
  description: string;
  descriptionElement?: JSX.Element;
  descriptionLink?: {
    title: string;
    link: string;
  };
  descriptionBold?: string;
  slicerFilters?: SlicerFilter[];
  blue?: boolean;
  reportId: string;
  powerBiPlaceholderImg?: string;
  scrollable?: boolean;
  comingSoon?: boolean;
  comingSoonImageUrl?: string;
  altDownloadLink?: string;
  openGovReport?: string;
  newTabLink?: string;
  noPaddingTop?: boolean;
};

const ReportSection = forwardRef<HTMLDivElement, ReportSectionProps>(
  (props, ref) => {
    const {
      title,
      description,
      descriptionLink,
      blue,
      reportId,
      slicerFilters,
      powerBiPlaceholderImg = "",
      scrollable,
      comingSoon,
      comingSoonImageUrl,
      altDownloadLink,
      descriptionBold,
      openGovReport,
      newTabLink,
      descriptionElement,
      noPaddingTop,
    } = props;
    const [readMore, setReadMore] = useState(true);

    const toggleReadMore = () => {
      setReadMore(!readMore);
    };

    const handleOpenGovUrl = (url: string) => {
      if (url.includes("<iframe") && url.includes("</iframe>")) {
        const srcMatch = url.match(/src="([^"]+)"/);
        if (srcMatch && srcMatch[1]) {
          return srcMatch[1];
        }
      }
      return url;
    };

    return (
      <div
        ref={ref}
        style={{
          backgroundColor: blue ? "#F0F3F4" : "#FCFAF5",
          paddingTop: noPaddingTop ? "0" : "4.5rem",
        }}
        className="report-data-section app-padding"
      >
        <div className="report-text-container" tabIndex={0}>
          <h5 className="report-data-header">{title}</h5>
          <p
            style={{ overflowY: scrollable ? "auto" : "unset" }}
            className="body-medium report-data-text"
          >
            {descriptionElement && descriptionElement}
            {description.length > 500 && !scrollable
              ? readMore
                ? (description + " " + descriptionBold).slice(0, 500) + "..."
                : description
              : description}
            {descriptionLink?.link && (
              <a
                href={descriptionLink.link}
                rel="noopener noreferrer"
                target="_blank"
                aria-label={`Link to ${descriptionLink.title} report`}
              >
                {descriptionLink.title}
              </a>
            )}
            <b>{scrollable && (descriptionBold ? descriptionBold : "")}</b>
          </p>
          {!scrollable && (
            <p onClick={toggleReadMore} className="keep-reading-more-button">
              {description.length > 500
                ? readMore
                  ? "Read more"
                  : "Read less"
                : null}
            </p>
          )}
        </div>
        {comingSoon || openGovReport === "" ? (
          <div className="coming-soon-container">
            <button className="coming-soon-button">COMING SOON</button>
            <img
              className="coming-soon-report-image"
              src={
                comingSoonImageUrl ??
                API_CONSTANTS.imageBucket +
                  "/powerbi-images/sco-report-image-6.jpg"
              }
            />
          </div>
        ) : (
          <div className="report-powerBi-container">
            {openGovReport ? (
              <div className="iframe-container">
                <h6>
                  {newTabLink && (
                    <a
                      href={handleOpenGovUrl(newTabLink)}
                      target="_blank"
                      aria-label={`Link to ${title} report`}
                      rel="noopener noreferrer"
                    >
                      ACCESS DETAILED REPORT HERE
                    </a>
                  )}
                </h6>
                <LazyLoadIframe
                  threshold={0.4}
                  iframeProps={{
                    scrolling: "no",
                  }}
                  src={handleOpenGovUrl(openGovReport)}
                  fallbackContent={
                    <div
                      style={{ background: "transparent", border: "none" }}
                      className="power-bi-container"
                    >
                      <img className="loader" src={Loader} alt="loading..." />
                    </div>
                  }
                />
              </div>
            ) : (
              <>
                <LazyLoadPowerBi
                  reportId={reportId}
                  slicerFilters={slicerFilters}
                  threshold={0.3}
                  placeholderImg={powerBiPlaceholderImg}
                />

                {altDownloadLink ? (
                  <div>
                    <a
                      href={altDownloadLink}
                      className="alt-download-link"
                      target={"_blank"}
                    >
                      <DownloadDataIcon iconSize={32} />
                      <span className="alt-download-report-data-text">
                        Due to file size, use this link to download the full
                        dataset.
                      </span>
                    </a>
                  </div>
                ) : (
                  <DownloadDataInformational />
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default ReportSection;
