import { forwardRef } from "react";
import { ThreeDotCircleIcon } from "../../../styles/icons";
import { VerticalCard } from "../../../components";
import { API_CONSTANTS, NAV_TAGS } from "../../../types/constants";
import { ReportsObject } from "../../../types/interfaces/commonInterfaces";
import "./stateAdditionalReports.scss";

type StateAdditionalReports = {
  reports?: ReportsObject;
};

const StateAdditionalReports = forwardRef<
  HTMLDivElement,
  StateAdditionalReports
>(({ reports }, ref) => {
  const readMoreText = "Explore Data";

  return (
    <>
      <div className="additional-reports-container app-padding">
        <div className="title-row">
          <ThreeDotCircleIcon className="icon" />
          <h4>Additional State Reports</h4>
        </div>
        <div className="more-high-level-cards-container">
          <span>
            <div className="more-high-level-cards cards">
              <VerticalCard
                cardTitle={"Transactions"}
                cardDescription="The Transaction Report provides a detailed listing of every financial transaction made by the state."
                cardTag={NAV_TAGS.STATE}
                image={
                  API_CONSTANTS.imageBucket +
                  "/ui-images/sco-ui-transaction.png"
                }
                readMoreText={readMoreText}
                readMoreAction={() => {
                  window.open(reports?.["Transaction"]?.URLs, "_blank");
                }}
              />
              <VerticalCard
                cardTitle={"Travel"}
                cardDescription="The Employee Travel Report provides a comprehensive breakdown of expenses accrued during employees' travels."
                cardTag={NAV_TAGS.STATE}
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-travel.png"
                }
                readMoreText={readMoreText}
                readMoreAction={() => {
                  window.open(reports?.["Travel"]?.URLs, "_blank");
                }}
              />
              <VerticalCard
                cardTitle="Employer Payroll Cost"
                cardDescription={
                  "The Payroll Costs Report is an overview of statewide employer-paid benefits for state employees such as retirement, insurance, and more."
                }
                cardTag={NAV_TAGS.STATE}
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-payroll.png"
                }
                readMoreText={readMoreText}
                readMoreAction={() => {
                  window.open(
                    reports?.["Employer Payroll Costs"]?.URLs,
                    "_blank"
                  );
                }}
              />
            </div>
            <div className="more-high-level-cards">
              <VerticalCard
                cardTitle={"Gen Fund Revenue & Expenditure"}
                cardDescription="The General Fund Revenue and Expenditure Report tracks outgoing payments and incoming revenues in the state's largest fund."
                cardTag={NAV_TAGS.STATE}
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-gen-fund.png"
                }
                readMoreText={readMoreText}
                readMoreAction={() => {
                  window.open(
                    reports?.["General Fund Revenue and Expenditure"]?.URLs,
                    "_blank"
                  );
                }}
              />
              <VerticalCard
                cardTitle={"Employee Count"}
                cardDescription="The Employee Count Report is a resource offering insights into the ongoing employment numbers for all state agencies."
                cardTag={NAV_TAGS.STATE}
                image={
                  API_CONSTANTS.imageBucket +
                  "/ui-images/sco-ui-employee-count.png"
                }
                readMoreText={readMoreText}
                readMoreAction={() => {
                  window.open(reports?.["Employee Count"]?.URLs, "_blank");
                }}
              />
              <VerticalCard
                cardTitle="Workforce Tenure"
                cardDescription={
                  "The Workforce Tenure Report shows the duration of service and the collective experience of our workforce."
                }
                cardTag={NAV_TAGS.STATE}
                image={
                  API_CONSTANTS.imageBucket +
                  "/ui-images/sco-ui-employee-pay-rates.png"
                }
                readMoreText={readMoreText}
                readMoreAction={() => {
                  window.open(reports?.["Workforce Tenure"]?.URLs, "_blank");
                }}
              />
            </div>
          </span>
        </div>
      </div>
    </>
  );
});
export default StateAdditionalReports;
