import {
  Dispatch,
  SetStateAction,
  useState,
  FC,
  useRef,
  useEffect,
} from "react";
import { ChevronDown, ChevronUp } from "styled-icons/boxicons-solid";
import { Close } from "styled-icons/material-rounded";
import {
  EntityFilter,
  EntityFinancialFilter,
} from "../../types/interfaces/entityInterfaces";
import "./searchComponent.scss";
import { UrbanRenewalIcon } from "../../styles/icons";

interface searchDropDownComponentProps {
  selectedItem: EntityFilter | EntityFinancialFilter | undefined;
  setSelectedItem: Dispatch<SetStateAction<any>>;
  entitiesData?: EntityFilter[];
  isEntitiesFiltered?: boolean;
  DefaultIcon?: FC<React.SVGProps<SVGSVGElement>>;
  allIcon?: boolean;
  CustomIcon?: FC<React.SVGProps<SVGSVGElement>>;
}

const SearchDropDownComponent: FC<searchDropDownComponentProps> = ({
  selectedItem,
  setSelectedItem,
  isEntitiesFiltered = true,
  entitiesData,
  DefaultIcon,
  allIcon,
  CustomIcon,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterText, setFilterText] = useState<string>("");
  const catMenuRef = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (
        catMenuRef.current &&
        !catMenuRef.current.contains(e.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleFilterSearch = (): EntityFilter[] | undefined => {
    return isEntitiesFiltered
      ? entitiesData?.filter((item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : undefined;
  };

  const handleClick = (item: EntityFilter) => {
    if (!isEntitiesFiltered && item.hasThisEntity === "FALSE") return;
    setSelectedItem(item);
    setDropdownOpen(false);
    setFilterText("");
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number,
    totalOptions: number,
    item: any
  ) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleClick(item);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      focusNextOption(index, totalOptions);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      focusPreviousOption(index, totalOptions);
    } else if (e.key === "Escape") {
      setDropdownOpen(false);
      const activeEl = document.activeElement as HTMLElement | SVGElement;
      if (activeEl?.blur) {
        activeEl.blur();
      }
    }
  };

  const focusNextOption = (currentIndex: number, totalOptions: number) => {
    const nextIndex = (currentIndex + 1) % totalOptions;
    optionRefs.current[nextIndex]?.focus();
  };

  const focusPreviousOption = (currentIndex: number, totalOptions: number) => {
    const previousIndex = (currentIndex - 1 + totalOptions) % totalOptions;
    optionRefs.current[previousIndex]?.focus();
  };

  return (
    <div className="filter-container" ref={catMenuRef}>
      <div onClick={toggleDropdown} className="selected-container">
        <div className="selected-icon-text">
          {selectedItem?.Icon && <selectedItem.Icon className="icon-entity" />}
          {!selectedItem?.Icon && DefaultIcon && <DefaultIcon />}
          {allIcon && <UrbanRenewalIcon />}
          {CustomIcon && <CustomIcon />}
          {selectedItem?.name}
        </div>

        {dropdownOpen ? (
          <div
            tabIndex={0}
            aria-label="Close dropdown button"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                toggleDropdown();
              }
              if (e.key === "Escape") {
                const activeEl = document.activeElement as
                  | HTMLElement
                  | SVGElement;
                if (activeEl?.blur) {
                  activeEl.blur();
                }
              }
            }}
          >
            <ChevronUp
              onClick={toggleDropdown}
              className="chevron"
              size={25}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  toggleDropdown();
                }
                if (e.key === "Escape") {
                  const activeEl = document.activeElement as
                    | HTMLElement
                    | SVGElement;
                  if (activeEl?.blur) {
                    activeEl.blur();
                  }
                }
              }}
            />
          </div>
        ) : (
          <div
            tabIndex={0}
            aria-label="Open dropdown button"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                toggleDropdown();
              }
              if (e.key === "Escape") {
                const activeEl = document.activeElement as
                  | HTMLElement
                  | SVGElement;
                if (activeEl?.blur) {
                  activeEl.blur();
                }
              }
            }}
          >
            <ChevronDown
              onClick={toggleDropdown}
              className="chevron"
              size={25}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  toggleDropdown();
                }
                if (e.key === "Escape") {
                  const activeEl = document.activeElement as
                    | HTMLElement
                    | SVGElement;
                  if (activeEl?.blur) {
                    activeEl.blur();
                  }
                }
              }}
            />
          </div>
        )}
      </div>
      {dropdownOpen && (
        <div id="wrap" className="select-box">
          <div className="input-box">
            <input
              placeholder="Search..."
              value={filterText}
              onChange={(event) => setFilterText(event.target.value)}
              className="input"
            />
            {filterText && (
              <Close onClick={() => setFilterText("")} color="gray" size={25} />
            )}
          </div>
          <div className="list-container">
            {handleFilterSearch()?.map((item, index) => (
              <div
                key={`${item.name}-${index}`}
                ref={(el) => (optionRefs.current[index] = el)}
                tabIndex={0}
                onClick={() => handleClick(item)}
                onKeyDown={(e) =>
                  handleKeyDown(
                    e,
                    index,
                    handleFilterSearch()?.length || 0,
                    item
                  )
                }
                className={`selects-container ${
                  isEntitiesFiltered && item.hasThisEntity === "FALSE"
                    ? "-no-entity"
                    : ""
                }`}
              >
                {item.Icon && (
                  <>
                    <p>{item.Icon.displayName}</p>
                    <item.Icon className="icon-entity-selects" />
                  </>
                )}
                {!item.Icon && DefaultIcon && (
                  <DefaultIcon className="icon-entity-selects" />
                )}
                <p
                  className={`select-text ${
                    isEntitiesFiltered && item.hasThisEntity === "FALSE"
                      ? "-no-entity"
                      : ""
                  }`}
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchDropDownComponent;
